//Custom Pointer

function customCursor(curDotVar) {
  const cursor = curDotVar({
    background: "#000",
    easing: 4,
    scale: 0.5,
  })

  cursor.setAttribute("class", "customCursor")
  cursor.setAttribute("id", "cc")
  cursor.style.zIndex = "9999"

  //Inner div inside custom cursor
  let cursorInner = document.createElement("div")
  cursorInner.id = "cursorTxt"
  cursorInner.className = "cursorIn"
  cursor.appendChild(cursorInner)

  let t = document.createTextNode("Scroll")
  cursorInner.appendChild(t)

  cursor.over("div", {
    background: "#FFFFFF",
    scale: 1,
  })
}
//module.exports = customCursor;
module.exports = customCursor
