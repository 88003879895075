//import React from "react"
import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ogImageSq from "../../content/assets/velvetzoo-og-image-1200.jpg"
import updateNavbarColor from "../modules/updateNavbarColor"
//import modifyGoogleDriveUrl from "../modules/modifyGoogleDriveUrl"
function triggerTransitionAnimations() {
  const transitionDiv = document.getElementById("transition")
  const siteMain = document.getElementById("site-main")

  function animateBodyElementsOnLoad() {
    siteMain.classList.add("animations-in")
  }

  function resetTransitionMoveDiv() {
    transitionDiv.classList.remove("isMovingOut")
    transitionDiv.classList.add("reset")
    //console.log("isMovingOut class removed - reset class added")
  }

  function transitionDivClasses() {
    transitionDiv.classList.add("isMovingOut")
    setTimeout(resetTransitionMoveDiv, 1000)
  }

  if (typeof window !== "undefined") {
    setTimeout(transitionDivClasses, 500)
    setTimeout(animateBodyElementsOnLoad, 500)
    setTimeout(function() {
      updateNavbarColor(".check-contrast")
    }, 500)
  }
}

class WorkTemplate extends React.Component {
  componentDidMount() {
    triggerTransitionAnimations()
    let siteHeader = document.getElementById("siteHeader")
    siteHeader.classList.add("lighter")

    /*const videoTags = document.querySelectorAll("video")
    if (videoTags) {
      modifyGoogleDriveUrl(videoTags)
    }*/
  }
  render() {
    const work = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    let workThumbnail = work.frontmatter.thumbnail.childImageSharp.fluid.src
    const imageSq = ogImageSq
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    const workLinkUrl = work.frontmatter.workUrl
    if (workLinkUrl !== null) {
      var workPrintUrl = workLinkUrl.replace(/^https?:\/\//i, "")
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={work.frontmatter.title + " | Velvetzoo project"}
          description={
            work.frontmatter.description + " " + work.frontmatter.longDesc
          }
          image={siteUrl + workThumbnail}
          imageSq={siteUrl + imageSq}
        />
        <Helmet>
          <body className="work" id="body" />
        </Helmet>
        <div className="work-content">
          <div className="work-content-header check-contrast">
            <div className="work-content-header__text">
              <h1 className="work-content-header__title txtAnim_1">
                <span className="big-font">{work.frontmatter.title}</span>
              </h1>
              {work.frontmatter.description && (
                <p className="work-content-header__excerpt txtAnim_2">
                  {work.frontmatter.clientDesc}
                </p>
              )}
            </div>

            <div
              className="work-content-header__image"
              style={{ backgroundImage: `url(${workThumbnail})` }}
            >
              {""}
            </div>
          </div>

          <div className="work-content-description">
            <div>
              <h2 className={"h3-font"}>Project</h2>
              <p>{work.frontmatter.longDesc}</p>
              <i>{work.frontmatter.tags}</i>
            </div>
            <div>
              <h3>Work</h3>
              <p>{work.frontmatter.workDesc}</p>

              {workLinkUrl !== null && (
                <>
                  <h4>Website</h4>
                  <a
                    className="link"
                    href={workLinkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {workPrintUrl}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>

        <article className={`post-content`}>
          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: work.html }}
          />
        </article>
      </Layout>
    )
  }
}

export default WorkTemplate

export const pageQuery = graphql`
  query WorkCardBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        longDesc
        clientDesc
        workDesc
        workUrl
        tags
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
