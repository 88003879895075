function linkAfterAnimations(linkTo, linkToTimeOut) {
  let linkLocationHref = 'location.href="' + linkTo + '"'
  let siteMain = document.getElementById("site-main")
  setTimeout(linkLocationHref, linkToTimeOut)
  siteMain.classList.add("transition-slide_up")
  document.getElementById("transition").classList.remove("reset")
  document.getElementById("transition").classList.add("isMoving")
}

module.exports = linkAfterAnimations
