import React from "react"
import { Link } from "gatsby"
import logoEyeFrame from "../../content/assets/vzEye_eyeFrame.png"
import logoEyeIris from "../../content/assets/vzEye_iris.png"
import { isMobile } from "../modules/checkIfMobile"
import customCursor from "../modules/customCursor"
import curDot from "../modules/cursor-triangle"
import linkAfterAnimations from "../modules/linkAfterAnimations"
//import movingEyeLogo from "../modules/movingEyeLogo"

//console.log(customCursor);
if (typeof window !== "undefined") {
  const mq = window.matchMedia("(min-width: 960px)")
  if (!isMobile.any() || mq.matches) {
    customCursor(curDot)
  }
}

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)

  if (typeof window !== "undefined") {
    let siteBody = document.querySelector("body")

    if (toggleNav) {
      siteBody.classList.add("no-scroll")
    } else {
      siteBody.classList.remove("no-scroll")
    }

    //movingEyeLogo('eye_iris',1500)
  }

  return (
    <div className="site-wrapper">
      <header
        id="siteHeader"
        className={`site-head ${toggleNav ? `site-head-open` : ``}`}
      >
        <div className="site-head-container">
          <nav id="swup" className="site-head-left">
            <ul className="nav site-head-links">
              <li className="nav-home">
                <Link
                  to={`/`}
                  onClick={e => {
                    e.preventDefault()
                    linkAfterAnimations("/", "1100")
                  }}
                >
                  Home
                </Link>
              </li>

              <li className="nav-about">
                <Link
                  to={`/works`}
                  onClick={e => {
                    e.preventDefault()
                    linkAfterAnimations("/works", "1100")
                  }}
                >
                  Works
                </Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link
              className="site-head-logo"
              to={`/`}
              id="homeLink"
              onClick={e => {
                e.preventDefault()
                let link2Home = `"/"`
                let workLocationHref = "location.href =  " + link2Home + ""
                let siteMain = document.getElementById("site-main")
                setTimeout(workLocationHref, 1100)
                siteMain.classList.add("transition-slide_up")
                document.getElementById("transition").classList.remove("reset")
                document.getElementById("transition").classList.add("isMoving")
              }}
            >
              <div className="logo-wrapper">
                <div id="velvetzooLogo" className="vz-eye">
                  <img
                    className="eyeFrame"
                    src={logoEyeFrame}
                    alt="velvetzoo logo"
                    height="90px"
                    width="90px"
                  />
                  <img
                    id="eye_iris"
                    className="eyeIris"
                    src={logoEyeIris}
                    alt="velvetzoo logo"
                    height="90px"
                    width="90px"
                  />
                </div>
              </div>
            </Link>
          </div>
          <div className="site-head-right">
            <ul className="nav site-head-links">
              <li className="nav-services">
                <Link
                  to={`/services`}
                  onClick={e => {
                    e.preventDefault()
                    linkAfterAnimations("/services", "1100")
                  }}
                >
                  Services
                </Link>
              </li>

              <li className="nav-about">
                <Link
                  to={`/about`}
                  onClick={e => {
                    e.preventDefault()
                    linkAfterAnimations("/about", "1100")
                  }}
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>

      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> | Made
        with ❤️ in Barcelona
      </footer>

      {/*Animated transition div*/}
      <div id="transition" className="transition-move fadeOut">
        {""}
      </div>

      {/*Bottom nav*/}
      <div id="bottomNav" className="bottomNav">
        <button
          className="nav-burger"
          aria-label="Menu"
          type="button"
          onClick={() => setToggleNav(!toggleNav)}
        >
          <div id="b_menu" className={`${toggleNav ? `cross` : ``}`}>
            <svg viewBox="0 0 800 600">
              <path
                d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                id="top"
              >
                {""}
              </path>
              <path d="M300,320 L540,320" id="middle">
                {""}
              </path>
              <path
                d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                id="bottom"
                transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
              >
                {""}
              </path>
            </svg>
          </div>
        </button>
        <div className="bottomNav_bg">{""}</div>
      </div>
      <div className="bottomNav_mobile-menu-bg">{""}</div>
    </div>
  )
}

export default Layout
